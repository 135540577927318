import { render, staticRenderFns } from "./ModalTicket.vue?vue&type=template&id=45a2a327&scoped=true"
import script from "./ModalTicket.vue?vue&type=script&lang=js"
export * from "./ModalTicket.vue?vue&type=script&lang=js"
import style0 from "./ModalTicket.vue?vue&type=style&index=0&id=45a2a327&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a2a327",
  null
  
)

export default component.exports