<template>
  <b-overlay
    :show="show"
  >
    <b-card-text>
      <h4 align="center">
        {{ getTitulo() }} Ticket
      </h4>
      <b-row>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <b-form-group>
            <h6>Fallas del Ticket</h6>
            <v-select
              v-model="fallaInicioTicket"
              label="nombre"
              :options="fallasInicioTicket"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <b-form-group>
            <h6>Trabajos Correctivos</h6>
            <v-select
              v-model="trabajosRealizar"
              label="nombre"
              multiple
              :options="trabajosCorrectivos"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <h6>Foto de Inicio</h6>
        </b-col>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <center>
            <imagen
              :key="'inicio'"
              :leyenda="'inicio'"
              @cargar-file="imagenInicio"
            />
          </center>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
        <b-col
          v-if="operacion === 'I'"
          cols="12"
        >
          <br>
          <h6>Foto del Seguimiento</h6>
        </b-col>
        <b-col
          v-if="operacion === 'V'"
          cols="12"
        >
          <br>
          <h6>Foto de la Verificación</h6>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
        <b-col
          v-if="operacion === 'I' || operacion === 'V'"
          cols="12"
        >
          <center>
            <imagen
              :key="'seguimiento'"
              :leyenda="'seguimiento'"
              @cargar-file="imagenPanoramica"
            />
          </center>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
        <b-col
          v-if="operacion === 'F'"
          cols="12"
        >
          <h6>Foto de {{ item === '' ? 'Finalización' : 'Seguimiento' }}</h6>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
        <b-col
          v-if="operacion === 'F'"
          cols="12"
        >
          <center>
            <imagen
              :key="'fin'"
              :leyenda="'fin'"
              @cargar-file="imagenFin"
            />
          </center>
        </b-col>
        <b-col cols="12">
          <h5 v-if="debeIncluirJustificacionTiempo">
            Comentario y Justificación del Tiempo
          </h5>
          <h5 v-else>
            Comentarios
          </h5>
          <b-form-textarea
            id="comentarios"
            v-model="comentarios"
            rows="2"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="align-content-center"
        >
          <center>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1 align-self-center"
              @click="guardarTicket"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Guardar Seguimiento</span>
            </b-button>
          </center>
          <br>
        </b-col>
      </b-row>
    </b-card-text>
  </b-overlay>
</template>

<script>
import {
  BButton, BCardText, BCol, BFormTextarea, BOverlay, BRow, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import { updateCreateTicket } from '@/utils/tickets'
import { sendFile } from '@/utils/files'
import { getlistado } from '@/utils/catalogos'
import { calcularTiempoTranscurrido } from '@/utils/funciones'

export default {
  name: 'TicketsSeguimiento',
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BFormTextarea,
    Imagen,
    BButton,
    vSelect,
  },
  fields: ['Tipo', 'Accesorio'],
  tableVariants: [
    'primary',
  ],
  striped: true,
  borderless: true,
  bordered: false,
  outlined: false,
  small: true,
  hover: false,
  dark: false,
  fixed: true,
  footClone: false,
  headVariant: null,
  noCollapse: false,
  tableVariant: 'primary',
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      seguimiento: null,
      localization: null,
      show: false,
      fotoPanoramica: '',
      comentarios: '',
      fotoInicio: '',
      fotoFin: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
      trabajosRealizar: null,
      fallaInicioTicket: '',
      fallasInicioTicket: [],
      trabajosCorrectivos: [],
    }
  },
  computed: {
    debeIncluirJustificacionTiempo() {
      const tiempoCreacion = this.calcularTiempoTranscurrido(this.ticket.fechaCreacion)
      return (tiempoCreacion >= 60 && this.operacion === 'V') || (tiempoCreacion >= 720 && this.operacion === 'F')
    },
  },
  async created() {
    this.fallasInicioTicket = await getlistado(20, 'Trabajos Ticket', false, this.usuario)
    this.trabajosCorrectivos = await getlistado(33, 'Trabajos Correctivos', false, this.usuario)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    calcularTiempoTranscurrido() {
      return calcularTiempoTranscurrido(this.ticket.fechaCreacion)
    },
    getTitulo() {
      if (this.item === '') {
        if (this.operacion === 'N') return 'Iniciar'
        if (this.operacion === 'V') return 'Verificar'
        return this.operacion === 'F' ? 'Finalizar' : 'Seguimiento'
      }
      return 'Seguimiento'
    },
    async guardarTicket() {
      if (this.fallaInicioTicket === '' && this.operacion === 'N') {
        this.error('Debe elegir una falla observable antes de empezar el Ticket!')
        return
      }
      if (this.fotoInicio === '' && this.operacion === 'N') {
        this.error('Debe ingresar una foto del inicio del Ticket!')
        return
      }

      if (this.fotoPanoramica === '' && this.operacion === 'I') {
        this.error('Debe ingresar una foto del Seguimiento!')
        return
      }
      if (this.fotoPanoramica === '' && this.operacion === 'V') {
        this.error('Debe ingresar una foto de la Verificación!')
        return
      }
      const tiempoCreacion = this.calcularTiempoTranscurrido(this.ticket.fechaCreacion)
      if (tiempoCreacion >= 60 && this.comentarios === '' && this.operacion === 'V') {
        this.error('Debe Ingresar un Comentario y su Justificación del Tiempo!')
        return
      }
      if (this.fotoFin === '' && this.operacion === 'F' && this.item === '') {
        this.error('Debe ingresar una foto del fin de la Atención!')
        return
      }
      if (tiempoCreacion >= 720 && this.comentarios === '' && this.operacion === 'F') {
        this.error('Debe Ingresar un Comentario y su Justificación del Tiempo!')
        return
      }
      if (this.fotoFin === '' && this.operacion === 'F' && this.item !== '') {
        this.error('Debe ingresar una foto del Seguimiento!')
        return
      }
      this.show = true

      const urlBucket = `tickets/${this.ticket.id}`

      if (this.localization === null || this.localization === undefined) {
        this.error('Error obteniendo geolocalización, intente nuevamente!')
        this.show = false
        return
      }

      let imagen = null
      if (this.operacion === 'N') { // foto de inicio ticket
        imagen = await sendFile(this.fotoInicio, 'images', urlBucket)
      } else if (this.operacion === 'I' || this.operacion === 'V') { // foto de tracking de item
        imagen = await sendFile(this.fotoPanoramica, 'images', urlBucket)
      } else if (this.operacion === 'F') { // foto fin ticket
        imagen = await sendFile(this.fotoFin, 'images', urlBucket)
      }

      if (imagen !== null) {
        const file = imagen.data
        file.localization = this.localization
        // eslint-disable-next-line no-nested-ternary
        file.tipo = this.operacion === 'N' ? 'Inicio Ticket' : this.operacion === 'I' ? 'Seguimiento Ticket' : 'Finalización Ticket'
        if (this.operacion === 'I') file.tipo = 'Seguimiento Ticket'
        if (this.operacion === 'V') file.tipo = 'Verificación Ticket'
        file.fechaHora = new Date()
        file.isMobile = window.screen.width <= 760
        file.comentarios = this.comentarios
        file.fallaInicioTicket = this.fallaInicioTicket
        if (this.operacion === 'N' && this.trabajosRealizar !== null) {
          if (this.operacion === 'V' && !this.ticket.trabajosCorrectivos) {
            this.ticket.trabajosCorrectivos = []
          }
          if (this.operacion === 'V' && !this.trabajosRealizar) {
            this.trabajosRealizar = []
          }
          this.ticket.trabajosCorrectivos = this.trabajosRealizar.map(trabajo => ({
            ...trabajo,
            estado: 'INICIO',
            fecha: new Date(),
          }))
        }
        file.usuario = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
          telefono: '',
        }

        if (this.operacion === 'I' || this.operacion === 'V') {
          this.ticket.seguimiento.push(file)
        } else this.ticket.seguimiento.push(file)
      }
      if (this.ticket.punto) delete this.ticket.punto
      if (this.operacion !== 'F') delete this.ticket.grupo
      if (this.operacion !== 'F') delete this.ticket.fechaFinalizacion
      else this.ticket.fechaFinalizacion = new Date()
      if (this.operacion === 'N') {
        this.ticket.estado = 'EN CURSO'
      } else {
        // se comenta ya que no se debería de asignar acá el usuario
        if (this.item === 'ATENDER') {
          this.ticket.tecnicoEncargado = {
            id: this.usuario.id,
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
            telefono: '',
          }
        }
        this.ticket.estado = this.operacion === 'F' ? 'FINALIZADA' : this.ticket.estado
        if (this.operacion === 'F' && this.item !== '') {
          if (!this.ticket.fechaVerificacion) {
            if (!this.ticket.trackingEstados) this.ticket.trackingEstados = []
            this.ticket.trackingEstados.push({
              estado: 'VERIFICADO',
              horaFecha: new Date(),
            })
            this.ticket.fechaVerificacion = new Date()
          }
          this.ticket.trackingEstados.push({
            estado: 'ASIGNADO',
            horaFecha: new Date(),
          })
        }
        if (this.operacion === 'V') {
          this.ticket.estado = 'VERIFICADO'
          this.ticket.fechaVerificacion = new Date()
        }

        if (this.ticket.estado === 'FINALIZADA') this.ticket.fechaAtencion = new Date()
      }

      if (imagen !== null) {
        if (!this.ticket.direccion) delete this.ticket.direccion
        if (!this.ticket.matricula) delete this.ticket.matricula

        await updateCreateTicket(this.ticket, 1)
        this.success('Ticket', 'Ticket Actualizado Correctamente!!')
        this.$emit('cerrar-dialog')
      } else {
        this.error('Error almacenando fotografía, intente nuevamente!')
      }
      this.show = false
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    imagenPanoramica(file) {
      this.fotoPanoramica = file
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
