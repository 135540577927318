<template>
  <div>
    <!--- INFORMACIÓN GENERAL -->
    <b-row>
      <b-col cols="12">
        <h5 style="color: #006394;">
          Información General
        </h5>
      </b-col>
      <b-col
        v-if="ticket.canal"
        cols="4"
      >
        <label>Canal de Ingreso</label>
        <b-form-input
          :value="ticket.canal"
          disabled
        />
      </b-col>
      <b-col :cols="ticket.seguimiento && ticket.seguimiento[1] && ticket.seguimiento[1].fallaInicioTicket ? '4' : '8'">
        <label>Persona Reporta</label>
        <b-form-input
          :value="ticket.usuarioReporta ? ticket.usuarioReporta.nombre : ''"
          disabled
        />
      </b-col>
      <b-col
        v-if="ticket.seguimiento && ticket.seguimiento[1] && ticket.seguimiento[1].fallaInicioTicket"
        cols="4"
      >
        <label>Fallas Inicio de Ticket</label>
        <b-form-input
          :value="ticket.seguimiento[1].fallaInicioTicket ? ticket.seguimiento[1].fallaInicioTicket.nombre : ''"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Municipio</label>
        <b-form-input
          :value="punto.obj_localidad.municipio.nombre"
          disabled
        />
      </b-col>
      <b-col cols="7">
        <label>Localidad / Población</label>
        <b-form-input
          :value="punto.obj_localidad.nombre"
          disabled
        />
      </b-col>
      <b-col cols="2">
        <label>Zona</label>
        <b-form-input
          :value="punto.obj_localidad.zona"
          disabled
        />
      </b-col>
      <b-col cols="6">
        <label>Comentarios</label>
        <b-form-textarea
          :value="ticket.comentarios"
          disabled
          no-resize
          rows="2"
        />
      </b-col>
      <b-col cols="6">
        <label>Ubicación</label>
        <b-button
          v-if="ticket.latitud !== '' && ticket.longitud !==''"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-primary"
          block
          @click="irRuta(ticket)"
        >
          <feather-icon icon="MapPinIcon" />
          <br>
          <br>
          Ver Ubicación en Google Maps
        </b-button>
      </b-col>
      <b-col cols="12">
        &nbsp;
      </b-col>
      <b-col
        v-if="ticket.canal === 'WEB'"
        cols="12"
      >
        <label>Dirección Reportada</label>
        <b-form-input
          :value="ticket.direccion"
          disabled
        />
      </b-col>
      <b-col
        v-if="ticket.canal && ticket.canal === 'INSPECCION' "
        :cols="12"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-asignar
          variant="relief-primary"
          block
          @click="mostrarInspeccion()"
        >
          Detalle de la Inspección
        </b-button>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <!--- DETALLE DEL PUNTO -->
    <b-row v-if="punto.id">
      <b-col cols="12">
        <h5 style="color: #006394;">
          Información del Punto de Iluminación
        </h5>
      </b-col>
      <b-col cols="3">
        <label>Número de Poste</label>
        <b-form-input
          :value="punto.numeroPoste"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Grupo</label>
        <b-form-input
          :value="getNombrePunto(punto.grupo)"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Serie Lámpara</label>
        <b-form-input
          :value="punto.serieLampara"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <br>
        <modal-detail-poste :id="punto.id" />
      </b-col>
      <b-col cols="12">
        <br>
        <patch-information
          v-if="ticket.estado === 'EN CURSO' || ticket.estado === 'FINALIZADA'"
          :id="punto.id"
          :punto="punto"
          :type="'S'"
          :button-text="'Actualizar Serie Lámpara'"
          @serieactualizada="actualizarSerieLampara"
        />
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <!--- INFORMACIÓN TICKET -->
    <b-row>
      <b-col cols="12">
        <h5 style="color: #006394;">
          Información del Ticket
        </h5>
      </b-col>
      <b-col cols="8">
        <label>Técnico Encargado</label>
        <b-form-input
          :value="ticket.tecnicoEncargado? ticket.tecnicoEncargado.nombre : ''"
          disabled
        />
      </b-col>
      <b-col cols="4">
        <label>Estado Ticket</label>
        <b-form-input
          :value="ticket.estado"
          disabled
        />
      </b-col>
      <b-col
        v-if="ticket.estado === 'PENDIENTE'"
        cols="12"
      >
        <br>
        <modal-ticket
          operacion="V"
          :ticket="ticket"
          item="VERIFICAR"
        />
      </b-col>
      <b-col
        v-if="ticket.estado === 'PENDIENTE' || ticket.estado === 'VERIFICADO'"
        cols="12"
      >
        <br>
        <modal-ticket
          operacion="F"
          :ticket="ticket"
          item="ATENDER"
        />
      </b-col>
      <b-col cols="12">
        <br>
        <h6 style="color: #006394;">
          Imágenes de la Falla
        </h6>
      </b-col>
      <b-col cols="12">
        <br>
        <b-table
          responsive
          :items="ticket.imagenes"
          :fields="fields"
        >
          <template #cell(tipo)="data">
            <span>{{ data.item.tipo }}</span>
          </template>
          <template #cell(fechaHora)="data">
            <span>{{ formatFecha(data.item.fechaHora) }}</span>
          </template>
          <template #cell(detalle)="data">
            <ImagenShowReemplazo
              :importado="'imagenesFallaTicket'"
              :ticket="ticket"
              :objeto-seleccionado="data.item"
              :origen="origenes.ticketsCorrectivos"
              :index-trabajo="data.index"
              @actualizar="cerrarDetalle"
            />
          </template>
        </b-table>
      </b-col>
      <b-col
        v-if="ticket.trabajosCorrectivos && ticket.trabajosCorrectivos.length > 0"
        cols="12"
      >
        <br>
        <h6 style="color: #006394;">
          Detalle de los Trabajos Correctivos
        </h6>
      </b-col>
      <b-col
        v-if="ticket.trabajosCorrectivos && ticket.trabajosCorrectivos.length > 0"
        cols="12"
      >
        <br>
        <vue-good-table
          :rows="ticket.trabajosCorrectivos"
          :columns="camposTrabajosCorrectivos"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'fechaHora'">{{ formatFecha(props.row.fecha) }}</span>
            <span v-else-if="props.column.field === 'detalle'">
              <b-button
                v-if="props.row.seguimiento && props.row.seguimiento.length > 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                @click="mostrarImagenTrabajosCorrectivos(props)"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
      <b-col cols="12">
        <br>
        <h6 style="color: #006394;">
          Seguimiento Ticket
        </h6>
      </b-col>
      <b-col cols="12">
        <br>
        <b-table
          responsive
          :items="ticket.seguimiento"
          :fields="camposTablaAmpliacion"
        >
          <template #cell(estado)="data">
            <span><b>{{ data.item.tipo }}</b></span>
          </template>
          <template #cell(usuario)="data">
            <span>{{ data.item.tipo === 'Anulación del Ticket' ? data.item.usuario.nombre : ticket.tecnicoEncargado ? ticket.tecnicoEncargado.nombre : '' }}</span>
          </template>
          <template #cell(comentario)="data">
            <span>{{ data.item.comentarios }}</span>
          </template>
          <template #cell(tiempo)="data">
            <b-badge :variant="getColorVariant(data.item, ultimoSeguimiento)">
              <span class="d-flex align-items-center justify-content-center">{{ formatMinutosTranscurridos(data.item, ultimoSeguimiento) }}</span>
            </b-badge>
          </template>
          <template #cell(fechaHora)="data">
            <span>{{ formatFecha(data.item.fechaHora) }}</span>
          </template>
          <!-- BOTÓN -->
          <template #cell(imagen)="data">
            <ImagenShowReemplazo
              v-if="data.item.url"
              :importado="'seguimientoTickets'"
              :ticket="ticket"
              :posicion-imagen="data.index"
              :objeto-seleccionado="data.item"
              :origen="origenes.ticketsCorrectivos"
              @actualizar="cerrarDetalle"
            />
            <h5 v-else>
              <center>
                -
              </center>
            </h5>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <div>
        <center>
          <b-img
            :src="urlImagen"
            fluid
            alt="Imagen Cargada')"
          />
        </center>
      </div>
    </b-modal>
    <b-modal
      v-model="verDialogItem"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Seguimiento Accesorio"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-table
              responsive
              :items="item? item.seguimiento : []"
              :fields="camposTablaAmpliacion"
            >
              <template #cell(estado)="data">
                <span><b>{{ data.item.tipo }}</b></span>
              </template>
              <template #cell(usuario)="data">
                <span>{{ data.item.usuario? data.item.usuario.nombre : ampliacion.usuario.nombre }}</span>
              </template>
              <template #cell(comentario)="data">
                <span>{{ data.item.comentarios }}</span>
              </template>
              <!--BOTON-->
              <template #cell(imagen)="data">
                <b-button
                  v-if="data.item.url"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  @click="mostrarImagen(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-model="dialogInspeccion"
      centered
      ok-title="Aceptar"
      ok-only
      size="lg"
      title="Detalle de Inspección"
    >
      <InspeccionRutaDetalle :inspeccion="inspeccion" />
    </b-modal>
    <b-modal
      v-model="verDialogoTrabajos"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <div>
        <b-row>
          <b-col
            v-if="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.codigoNuevo"
            cols="4"
          >
            <label>Potencia:</label>
            <b-form-input
              :value="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.potencia"
              disabled
            />
          </b-col>
          <b-col
            v-if="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.codigoNuevo"
            cols="4"
          >
            <label>Código anterior</label>
            <b-form-input
              :value="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.codigoAnterior"
              disabled
            />
          </b-col>
          <b-col
            v-if="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.codigoNuevo"
            cols="4"
          >
            <label>Código nuevo</label>
            <b-form-input
              :value="trabajoSeleccionado && trabajoSeleccionado.seguimiento[1] && trabajoSeleccionado.seguimiento[1].cambioLampara && trabajoSeleccionado.seguimiento[1].cambioLampara.codigoNuevo"
              disabled
            />
            <br>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              :columns="detalleTrabajosCorrectivos"
              :rows="trabajoSeleccionado && trabajoSeleccionado.seguimiento"
              style-class="vgt-table condensed"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'detalle'">
                  <ImagenShowReemplazo
                    :importado="'ticketsCorrectivos'"
                    :posicion-imagen="props.index"
                    :objeto-seleccionado="trabajoSeleccionado"
                    :index-trabajo="indexSeleccionado"
                    :ticket="ticket"
                    :clave="'seguimiento'"
                    :origen="origenes.ticketsCorrectivos"
                    @actualizar="cerrarDetalle"
                  />
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BFormInput, BFormTextarea, BButton, BImg, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { calcularFecha } from '@/utils/fechas'
import ModalDetailPoste from '@/components/PuntosIluminacion/ModalDetailPoste.vue'
import InspeccionRutaDetalle from '@/components/Inspecciones/InspeccionRutaDetalle.vue'
import ModalTicket from '@/components/Tickets/tecnico/ModalTicket.vue'
import PatchInformation from '@/components/PuntosIluminacion/PatchInformation.vue'
import { getPoste } from '@/utils/postes'
import { getlistado } from '@/utils/catalogos'
import { calcularMinutosTranscurridos } from '@/utils/funciones'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import { origenes } from '@/utils/origenesImagen'

export default {
  name: 'DetalleAmpliacion',
  directives: {
    Ripple,
  },
  components: {
    ImagenShowReemplazo,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BTable,
    BFormTextarea,
    BImg,
    ModalDetailPoste,
    PatchInformation,
    InspeccionRutaDetalle,
    ModalTicket,
    BBadge,
    VueGoodTable,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    punto: {
      type: Object,
      required: true,
    },
    inspeccion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      origenes,
      posicionImagen: 0,
      urlImagenTrabajos: '',
      trabajoSeleccionado: null,
      indexSeleccionado: 0,
      imagenesTrabajos: [],
      verDialogoTrabajos: false,
      grupo: '',
      inspeccionSelect: null,
      dialogInspeccion: false,
      item: null,
      verDialogItem: false,
      verDialogoDocumento: false,
      urlImagen: '',
      camposTablaAmpliacion: ['estado', 'comentario', 'usuario', 'fechaHora', 'tiempo', 'imagen'],
      camposTrabajosCorrectivos: [
        {
          label: 'Trabajo',
          field: 'nombre',
          thClass: 'vgt-center-left',
          tdClass: 'text-left',
        },
        {
          label: 'Código',
          field: 'codigo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Código Nocturno',
          field: 'codigoNocturno',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      detalleTrabajosCorrectivos: [
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      fields: ['tipo', 'fechaHora', 'detalle'],
      tableVariants: [
        'primary',
      ],
      striped: true,
      borderless: true,
      bordered: false,
      outlined: false,
      hover: false,
      dark: false,
      fixed: true,
      footClone: false,
      headVariant: null,
      noCollapse: false,
      tableVariant: 'primary',
      grupos: [],
      ultimoSeguimiento: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    this.grupos = await getlistado(12, 'Grupos', false, this.usuario)
  },
  methods: {
    getColorVariant(item, ultimoSeguimiento) {
      const minutos = calcularMinutosTranscurridos(item, ultimoSeguimiento)
      return minutos >= 60 ? 'light-danger' : 'light-success'
    },
    formatMinutosTranscurridos(item) {
      const minutos = this.calcularMinutosTranscurridos(item)
      if (minutos >= 60) {
        const horas = Math.floor(minutos / 60)
        const minutosRestantes = minutos % 60
        return `${horas} ${horas === 1 ? 'HORA' : 'HORAS'} ${minutosRestantes} MIN`
      }
      return `${minutos} MIN`
    },
    calcularMinutosTranscurridos(item) {
      const fechaHora = new Date(item.fechaHora)
      if (!this.ultimoSeguimiento) {
        this.ultimoSeguimiento = fechaHora
        return '0'
      }
      const minutos = Math.floor((fechaHora - this.ultimoSeguimiento) / 60000)
      return minutos.toString()
    },
    getNombrePunto(id) {
      const grupo = this.grupos.find(i => i.id === id)
      if (grupo) {
        return grupo.nombre
      }
      return `No se encontró el grupo con el ID ${id}`
    },
    actualizarSerieLampara(nuevoValor) {
      this.punto.serieLampara = nuevoValor
    },
    async recargarPunto() {
      this.punto = await getPoste(this.punto.id)
    },
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    mostrarPosicion(index) {
      this.posicionImagen = index
    },
    mostrarImagenTrabajosCorrectivos(row) {
      this.trabajoSeleccionado = row.row
      this.indexSeleccionado = row.index
      this.verDialogoTrabajos = true
    },
    mostrarDetalle(item) {
      this.item = item
      this.verDialogItem = true
    },
    mostrarInspeccion() {
      this.inspeccionSelect = this.inspeccion
      this.dialogInspeccion = true
    },
    irRuta(ticket) {
      if (ticket.imagenes.length === 0) return
      const registro = ticket.imagenes[0].localization

      if (registro.latitude === '' || registro.longitude === '') return
      const url = `https://maps.google.com/?q=${registro.latitude},${registro.longitude}`
      window.open(url, '_blank')
    },
    formatFecha(item) {
      return calcularFecha(item)
    },
    getCols() {
      if (this.ticket.canal === 'INSPECCION') return 6
      return 12
    },
    cerrarDetalle() {
      this.verDialogoTrabajos = false
      this.$emit('actualizar')
    },
  },
}
</script>

<style scoped>

</style>
