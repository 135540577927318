<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-asignar
      variant="relief-primary"
      block
      @click="verDetallePoste"
    >
      {{ buttonText }}
    </b-button>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Actualizar"
      size="xs"
      modal-class="modal-primary"
      centered
      title="Punto de Iluminación"
      @ok="handleOk"
    >
      <b-card class="card-poste">
        <b-row>
          <b-col cols="12">
            <label>Serie Lámpara:</label>
            <b-form-input v-model.trim="punto.serieLampara" />
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>

</template>

<script>
import {
  BModal, BCard, BButton, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { updateCreatePoste } from '@/utils/postes'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    punto: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    verDetallePoste() {
      this.ocultarDialogo()
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
    },
    handleOk() {
      if (this.punto.serieLampara.toString().trim() === '') {
        mensajeError('Debe ingresar una serie de la Lámpara!')
        return
      }

      const result = updateCreatePoste({
        id: this.id,
        serieLampara: this.punto.serieLampara,
      }, 1)

      if (result !== null) {
        mensajeInformativo('Actualización', 'Serie de la Lámpara Actualizada !')
        this.$emit('serieactualizada', this.punto.serieLampara)
        this.ocultarDialogo()
      } else {
        mensajeError('Ocurrio un error actualizando la Serie de la Lámpara!')
      }
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}

.card-poste .text {
  color: #211f1f70;
}
</style>
