<template>
  <b-overlay
    :show="show"
  >
    <b-card-text>
      <h2
        align="center"
        class="labels"
      >
        {{ origen !== 'TECNICO' ? 'Registro de Falla:' : 'Ingreso Trabajo Adicional' }}
      </h2>
      <br>
      <!--No. Poste-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            No. Poste:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="numeroPoste"
            :value="poste.numeroPoste"
            readonly
          />
        </b-col>
      </b-row>
      <!--Potencia-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            Potencia:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="potencia"
            :value="poste.potencia"
            readonly
          />
        </b-col>
      </b-row>
      <!--Serie de Lámpara-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            Serie Lámpara:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="serieLamp"
            :value="poste.serieLampara"
            readonly
          />
        </b-col>
      </b-row>
      <!--Grupo-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            Grupo:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="grupo"
            :value=" grupo? grupo.nombre : ''"
            readonly
          />
        </b-col>
      </b-row>
      <!--Tecnologia-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            Tecnología:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="tecnologia"
            :value="tecnologiaLampara? tecnologiaLampara.nombre: ''"
            readonly
          />
        </b-col>
      </b-row>
      <!--Modelo-->
      <b-row>
        <b-col cols="4">
          <h5 class="labels">
            Modelo:
          </h5>
        </b-col>
        <b-col cols="8">
          <b-form-input
            id="modulo"
            :value="modeloLampara? modeloLampara.nombre : ''"
            readonly
          />
        </b-col>
      </b-row>
      <b-row v-if="origen !== 'TECNICO'">
        <b-col cols="4">
          <br>
          <h5 class="labels">
            Status GUI
          </h5>
        </b-col>
        <b-col cols="8">
          <br>
          <b-form-checkbox
            v-model="poste.statusGUI"
            class="custom-control-secondary"
            name="check-button"
            switch
          />
        </b-col>
      </b-row>
      <b-col cols="12">
          &nbsp;
      </b-col>
      <!--
          <b-col cols="12">
              &nbsp; &nbsp;
          </b-col>

          <b-row>
            <b-col cols="12">
              <h5 class="labels">
                Foto y Número Poste
              </h5>
            </b-col>
            <b-col cols="12">
              <center>
                <imagen
                  :key="'imagenPoste'"
                  :leyenda="'imagenPoste'"
                  @cargar-file="imagenPoste"
                />
              </center>
            </b-col>
          </b-row>

          <b-col cols="12">
              &nbsp; &nbsp;
          </b-col>
          -->
      <b-row>
        <b-col cols="3">
          <h5 class="labels">
            Fuente
          </h5>
        </b-col>
        <b-col cols="9">
          <v-select
            v-model="ticket.fuente"
            label="nombre"
            style="font-size: x-small;"
            :options="fuentes"
          />
        </b-col>
        <b-col cols="12">
&nbsp;
        </b-col>
        <b-col cols="3">
          <h5 class="labels">
            Falla
          </h5>
        </b-col>
        <b-col cols="9">
          <v-select
            v-model="ticket.falla"
            label="nombre"
            style="font-size: x-small;"
            :options="fallas"
            @change="validarFalla"
          />
        </b-col>
        <b-col
          v-if="ticket.falla !==null"
          cols="12"
        >
          &nbsp;&nbsp;
        </b-col>
      </b-row>
      <b-col
        v-if="ticket.falla !==null"
        cols="12"
      >
        <h5 class="labels">
          Foto Falla
        </h5>
      </b-col>
      <b-col
        v-if="ticket.falla !==null"
        cols="12"
      >
        <center>
          <imagen
            :key="'falla'"
            :leyenda="'falla'"
            @cargar-file="imagenFalla"
          />
        </center>
      </b-col>
      <b-col cols="12">
          &nbsp;&nbsp;
      </b-col>
      <b-row>
        <b-col cols="12">
          <h5 class="labels">
            Observaciones
          </h5>
          <b-form-textarea
            id="comentarios"
            v-model="ticket.comentarios"
            rows="2"
            required
          />
        </b-col>
      </b-row>
      <b-col
        cols="12"
        class="align-content-center"
      >
        <center>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1 align-self-center"
            @click="guardarInspeccion"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Guardar {{ origen !== 'TECNICO' ? 'Falla' : 'Trabajo' }}</span>
          </b-button>
        </center>
        <br>
      </b-col>
    </b-card-text>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCardText, BFormInput, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { getItem, getlistado } from '@/utils/catalogos'
import Ripple from 'vue-ripple-directive'
import { sendFile } from '@/utils/files'
import { findTickets, getUtilsTickets, updateCreateTicket } from '@/utils/tickets'

export default {
  name: 'IngresoFalla',
  components: {
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BFormInput,
    BFormTextarea,
    Imagen,
    BFormCheckbox,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    poste: {
      type: Object,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
    idOrigen: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      fuentes: [],
      show: false,
      grupo: null,
      tecnologiaLampara: null,
      modeloLampara: null,
      tecnologia: null,
      fotoFalla: '',
      fotoPoste: '',
      fallas: [],
      localization: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
      ticket: {
        fechaCreacion: new Date(),
        idPuntoIluminacion: '',
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        idInspeccion: '',
        statusGUI: true,
        fuente: null,
        falla: null,
        comentarios: '',
        estado: 'PENDIENTE',
        imagenes: [],
        seguimiento: [],
        trackingEstados: [],
        punto: null,
        canal: '',
        usuarioReporta: JSON.parse(localStorage.getItem('userData')),
      },
    }
  },
  async created() {
    await this.cargarFallas()
    try {
      if (this.poste !== null) {
        this.modeloLampara = this.poste.modeloLampara ? await getItem(3, this.poste.modeloLampara) : { nombre: 'SIN MODELO' }
        this.tecnologiaLampara = await getItem(4, this.poste.tecnologiaLampara)
        this.grupo = this.poste.objGrupo
        this.ticket.idPuntoIluminacion = this.poste.id
        this.ticket.punto = this.poste
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.poste = { }
    }
    this.fuentes = getUtilsTickets(1, this.usuario)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async validarExistenciaTicket() {
      const filter = {
        where: {
          and: [
            { estado: { neq: 'ANULADO' } },
            { estado: { neq: 'FINALIZADA' } },
            { companyId: this.ticket.usuarioReporta.idCompany },
            { idPuntoIluminacion: this.ticket.idPuntoIluminacion },
            { 'falla.id': this.ticket.falla.id },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      const tickets = await findTickets(filter)
      return tickets.length > 0
    },
    async guardarInspeccion() {
      if (this.poste.comentarios === '') {
        this.error('Ingrese una Observación!')
        return
      }
      /*
      if (this.fotoPoste === '') {
        this.error('Debe ingresar una foto del Poste!')
        return
      }
      */

      if (!this.ticket.fuente) {
        this.error('Debe ingresar una Fuente del Ticket!')
        return
      }

      if (this.ticket.falla !== null && this.fotoFalla === '') {
        this.error('Debe ingresar una foto de la Falla!')
        return
      }

      if (this.usuario.company.name === 'APVN') {
        if (this.localization === null || this.localization === undefined) {
          this.error('Error obteniendo geolocalización, intente nuevamente!')
          this.show = false
          return
        }
      }

      const existe = await this.validarExistenciaTicket()
      if (existe) {
        this.error('Ya se encuentra un ticket abierto con esta falla!')
        this.show = false
        return
      }
      this.show = true

      this.ticket.canal = this.origen
      if (this.origen === 'INSPECCION') this.ticket.idInspeccion = this.idOrigen
      else delete this.ticket.idInspeccion

      this.ticket.usuarioReporta = {
        id: this.ticket.usuarioReporta.id,
        nombre: `${this.ticket.usuarioReporta.firstName} ${this.ticket.usuarioReporta.firstSurname}`,
        email: this.ticket.usuarioReporta.email,
        telefono: '',
      }

      if (this.origen === 'TECNICO') {
        const usuario = JSON.parse(localStorage.getItem('userData'))
        const tecnico = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          name: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
          telefono: '',
        }
        this.ticket.ticketOrigen = this.idOrigen
        this.ticket.estado = 'ASIGNADO'
        this.ticket.tecnicoEncargado = tecnico
        this.ticket.fechaVerificacion = new Date()
      }

      const saveTicket = await updateCreateTicket(this.ticket, 2)
      if (saveTicket !== null) {
        this.ticket.id = saveTicket.id
        const urlBucket = `tickets/${saveTicket.id}`
        /*
        const saveImagenPoste = await sendFile(this.fotoPoste, 'images', urlBucket)
        if (saveImagenPoste !== null) {
          saveImagenPoste.isMobile = window.screen.width <= 760
          saveImagenPoste.data.localization = this.localization
          saveImagenPoste.data.tipo = 'Imagen Poste'
          saveImagenPoste.data.fechaHora = new Date()
          this.ticket.imagenes.push(saveImagenPoste.data)
        }
        */
        const saveImagenFalla = await sendFile(this.fotoFalla, 'images', urlBucket)
        if (saveImagenFalla !== null) {
          saveImagenFalla.data.localization = this.localization
          saveImagenFalla.data.tipo = 'Imagen Falla'
          saveImagenFalla.data.fechaHora = new Date()
          this.ticket.imagenes.push(saveImagenFalla.data)
        }

        await updateCreateTicket(this.ticket, 1)
        this.success('Ticket', this.origen !== 'TECNICO' ? 'Falla Almacenada Correctamente!!' : 'Trabajo Ingresado Correctamente, este se debe atender como un ticket por aparte !')
        // this.$emit('cerrar-dialog')
        if (this.origen === 'INSPECCION') await this.$router.replace('/inspecciones/nocturnas')
        else this.$emit('cerrar-dialog')
      } else {
        this.error('Error almacenando Inspección, intente nuevamente!')
      }
      this.show = false
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    imagenFalla(file) {
      this.fotoFalla = file
    },
    imagenPoste(file) {
      this.fotoPoste = file
    },
    validarFalla() {
      if (this.inspeccion.falla === null) {
        this.fotoFalla = ''
      }
    },
    async cargarFallas() {
      this.fallas = await getlistado(7, 'Fallas', false, this.usuario)
      if (this.fallas === null) this.fallas = []
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.labels {
  color: #376FA8;
}
</style>
