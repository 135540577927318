<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-asignar
      :variant="variante"
      block
      @click="verDetallePoste"
    >
      {{ titulo }}
    </b-button>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      title="Ingreso de Ticket"
    >
      <b-card class="card-poste">
        <ingreso-falla
          :poste="punto"
          :origen="origen"
          :id-origen="idOrigen"
          @cerrar-dialog="ocultarDialogo"
        />
      </b-card>
    </b-modal>
  </div>

</template>

<script>
import { BModal, BCard, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import IngresoFalla from '@/components/Tickets/IngresoFalla.vue'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BCard,
    BButton,
    IngresoFalla,
  },
  directives: {
    Ripple,
  },
  props: {
    punto: {
      type: Object,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
    idOrigen: {
      type: String,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
      default: 'Ingreso Falla',
    },
    variante: {
      type: String,
      required: false,
      default: 'relief-danger',
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    verDetallePoste() {
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
      this.$emit('notificar-dialog')
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}

.card-poste .text {
  color: #211f1f70;
}
</style>
