<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-b-tooltip.hover.v-primary
          title="Ver Imagen"
          variant="primary"
          @click="abrirVisualizacion"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </b-col>
      <b-modal
        v-model="verVisualizacion"
        ok-title="Aceptar"
        ok-only
        no-close-on-backdrop
        modal-class="modal-primary"
        title="Detalle de la Imagen"
      >
        <b-row>
          <b-col cols="12">
            <div style="display: flex; justify-content: center">
              <b-img
                class="ImagenShowReemplazo"
                alt="Imagen Previsualización"
                :src="usuario.company.enabledReport ? imagenUrl : ''"
              />
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row v-if="usuario.role === 'ADMINISTRATOR' || usuario.role === 'ADMIN' || actualiza">
          <b-col
            class="d-flex justify-content-center"
            cols="12"
          >
            <b-button
              variant="warning"
              @click="abrirModal"
            >
              Actualizar Fotografía
              <feather-icon icon="ImageIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <div>
      <b-modal
        v-model="verDialogoImagen"
        no-close-on-backdrop
        title="Actualizar Fotografía"
        modal-class="modal-primary"
        cancel-variant="danger"
        ok-title="Aceptar"
        cancel-title="Cancelar"
        hide-footer
      >
        <div>
          <b-row>
            <b-col
              class="justify-content-center d-flex"
              cols="12"
            >
              <h4
                v-if="!previewUrl"
                class="text-uppercase"
              >
                Selecciona una fotografía 📷
              </h4>
              <img
                v-if="previewUrl"
                :src="previewUrl"
                class="imagen-preview"
                alt="Preview de la imagen seleccionada"
              >
            </b-col>
          </b-row>
          <hr style="margin-top: -5px; margin-bottom: -10px">
          <b-row>
            <b-col
              class="d-flex justify-content-center demo-inline-spacing mr-3"
              cols="12"
            >
              <b-button
                variant="info"
                @click="nuevaImagen"
              >
                Seleccionar Fotografía
                <feather-icon icon="ImageIcon" />
              </b-button>
              <b-button
                variant="success"
                @click="Guardar"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
              <input
                ref="fileInput"
                type="file"
                style="display: none;"
                accept="image/*"
                @change="onFileChange"
              >
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BImg, BModal, VBTooltip,
} from 'bootstrap-vue'
import { saveImagen } from '@/utils/files'
import config from '@/utils/config'
import RequestAdmin from '@/utils/request'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BModal,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    ticket: {
      type: Object,
      required: false,
    },
    objetoSeleccionado: {
      type: Object,
      required: false,
    },
    posicionImagen: {
      type: Number,
      required: false,
    },
    clave: {
      type: String,
      required: false,
    },
    origen: {
      type: Object,
      required: false,
      default: null,
    },
    urlImagen: {
      type: String,
      required: false,
    },
    importado: {
      type: String,
      required: false,
    },
    actualiza: {
      type: Boolean,
      default: true,
      required: false,
    },
    indexTrabajo: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      previewUrl: '',
      localization: null,
      selectedFile: null,
      verDialogoImagen: false,
      verVisualizacion: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    imagenUrl() {
      if (!this.ticket) {
        return this.urlImagen
      }
      if (this.importado === 'imagenesFallaTicket') {
        return this.objetoSeleccionado.url
      }
      if (this.importado === 'seguimientoTickets') {
        return this.objetoSeleccionado.url
      }
      if (this.importado === 'ticketsCorrectivos') {
        return this.objetoSeleccionado[this.clave][this.posicionImagen].url
      }
      if (this.importado === 'trabajosMigraciones') {
        return this.objetoSeleccionado[this.clave][this.posicionImagen].url
      }
      return this.ticket[this.clave][this.posicionImagen].url
    },
  },
  created() {
    // console.log('ticket', this.ticket)
    // console.log('url-imagen', this.urlImagen)
    // console.log('objetoseleccionado', this.objetoSeleccionado)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.log('Error obteniendo latitud y longitud', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async Guardar() {
      if (!this.selectedFile || this.selectedFile === '') {
        mensajeError('Debes seleccionar una imagen')
        return
      }

      if (!this.ticket) {
        this.$emit('nueva-foto', this.selectedFile)
        this.ocultarDialogo()
        return
      }

      const isMobile = window.screen.width <= 760
      const usuario = { ...this.usuario }
      const encargado = {
        id: usuario.id,
        nombre: `${usuario.firstName} ${usuario.firstSurname}`,
        email: usuario.email,
      }

      if (this.importado === 'imagenesFallaTicket') {
        const imagenesCopia = JSON.parse(JSON.stringify(this.ticket.imagenes))
        const urlBucket = `${this.origen.urlBucket}/${this.ticket.id}`
        const finalObjImagen = await saveImagen(this.selectedFile, urlBucket, encargado, '', this.localization)
        imagenesCopia[this.indexTrabajo].url = finalObjImagen.url
        imagenesCopia[this.indexTrabajo].urlInternal = finalObjImagen.urlInternal
        imagenesCopia[this.indexTrabajo].name = finalObjImagen.name
        imagenesCopia[this.indexTrabajo].isMobile = isMobile

        const patchObject = {
          id: this.ticket.id,
          imagenes: imagenesCopia,
        }

        const request = new RequestAdmin()
        const url = `${config.URL_BASE}${this.origen.urlApi}/${this.ticket.id}`
        await request.executePostPutPatch(url, patchObject, 'PATCH')
        this.ocultarDialogo()
        this.$emit('actualizar')
        mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
      } else if (this.importado === 'seguimientoTickets') {
        const seguimientoCopia = JSON.parse(JSON.stringify(this.ticket.seguimiento))
        const urlBucket = `${this.origen.urlBucket}/${this.ticket.id}`
        const finalObjImagen = await saveImagen(this.selectedFile, urlBucket, encargado, '', this.localization)
        seguimientoCopia[this.posicionImagen].url = finalObjImagen.url
        seguimientoCopia[this.posicionImagen].urlInternal = finalObjImagen.urlInternal
        seguimientoCopia[this.posicionImagen].name = finalObjImagen.name
        seguimientoCopia[this.posicionImagen].isMobile = isMobile

        const patchObject = {
          id: this.ticket.id,
          seguimiento: seguimientoCopia,
        }
        const request = new RequestAdmin()
        const url = `${config.URL_BASE}${this.origen.urlApi}/${this.ticket.id}`
        await request.executePostPutPatch(url, patchObject, 'PATCH')
        this.ocultarDialogo()
        this.$emit('actualizar')
        mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
      } else if (this.importado === 'ticketsCorrectivos') {
        const trabajosCopia = JSON.parse(JSON.stringify(this.ticket.trabajosCorrectivos))
        const urlBucket = `${this.origen.urlBucket}/${this.ticket.id}`
        const finalObjImagen = await saveImagen(this.selectedFile, urlBucket, encargado, '', this.localization)
        trabajosCopia[this.indexTrabajo][this.clave][this.posicionImagen].url = finalObjImagen.url
        trabajosCopia[this.indexTrabajo][this.clave][this.posicionImagen].urlInternal = finalObjImagen.urlInternal
        trabajosCopia[this.indexTrabajo][this.clave][this.posicionImagen].name = finalObjImagen.name
        trabajosCopia[this.indexTrabajo][this.clave][this.posicionImagen].isMobile = isMobile

        const patchObject = {
          id: this.ticket.id,
          trabajosCorrectivos: trabajosCopia,
        }

        const request = new RequestAdmin()
        const url = `${config.URL_BASE}${this.origen.urlApi}/${this.ticket.id}`
        await request.executePostPutPatch(url, patchObject, 'PATCH')
        this.ocultarDialogo()
        this.$emit('actualizar')
        mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
      } else if (this.importado === 'trabajosMigraciones') {
        // eslint-disable-next-line no-prototype-builtins
        const tipoTrabajo = this.objetoSeleccionado.tipoTrabajo.toLowerCase()
        // eslint-disable-next-line no-prototype-builtins
        if (this.ticket.trabajos.hasOwnProperty(tipoTrabajo)) {
          const trabajosCopia = JSON.parse(JSON.stringify(this.ticket.trabajos))
          const urlBucket = `${this.origen.urlBucket}/${this.ticket.id}`
          const finalObjImagen = await saveImagen(this.selectedFile, urlBucket, encargado, '', this.localization)
          trabajosCopia[tipoTrabajo].trabajos[this.indexTrabajo][this.clave][this.posicionImagen].url = finalObjImagen.url
          trabajosCopia[tipoTrabajo].trabajos[this.indexTrabajo][this.clave][this.posicionImagen].urlInternal = finalObjImagen.urlInternal
          trabajosCopia[tipoTrabajo].trabajos[this.indexTrabajo][this.clave][this.posicionImagen].name = finalObjImagen.name
          trabajosCopia[tipoTrabajo].trabajos[this.indexTrabajo][this.clave][this.posicionImagen].isMobile = isMobile

          const patchObject = {
            id: this.ticket.id,
            trabajos: trabajosCopia,
          }

          const request = new RequestAdmin()
          const url = `${config.URL_BASE}${this.origen.urlApi}/${this.ticket.id}`
          await request.executePostPutPatch(url, patchObject, 'PATCH')
          this.ocultarDialogo()
          this.$emit('actualizar')
          mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
        }
      } else {
        const urlBucket = `${this.origen.urlBucket}/${this.ticket.id}`
        const finalObjImagen = await saveImagen(this.selectedFile, urlBucket, encargado, '', this.localization)
        this.ticket[this.clave][this.posicionImagen].url = finalObjImagen.url
        this.ticket[this.clave][this.posicionImagen].urlInternal = finalObjImagen.urlInternal
        this.ticket[this.clave][this.posicionImagen].name = finalObjImagen.name
        this.ticket[this.clave][this.posicionImagen].isMobile = isMobile

        const patchObject = {
          id: this.ticket.id,
          [this.clave]: this.ticket[this.clave],
        }
        const request = new RequestAdmin()
        const url = `${config.URL_BASE}${this.origen.urlApi}/${this.ticket.id}`
        await request.executePostPutPatch(url, patchObject, 'PATCH')
        this.ocultarDialogo()
        mensajeInformativo('Fotografía Actualizada', 'Actualizado Correctamente')
      }
    },
    abrirVisualizacion() {
      this.verVisualizacion = true
    },
    abrirModal() {
      this.verDialogoImagen = true
    },
    nuevaImagen() {
      this.$refs.fileInput.click()
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.previewUrl = reader.result
        this.reduceCalidadImagen(this.selectedFile, 0.5)
      }
      reader.readAsDataURL(this.selectedFile)
    },
    reduceCalidadImagen(file, calidadDeseada) {
      const img = new Image()

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = img.width
        canvas.height = img.height

        ctx.drawImage(img, 0, 0)

        canvas.toBlob(blob => {
          const nuevaImagen = new File([blob], file.name, { type: 'image/jpeg' })
          this.selectedFile = nuevaImagen
        }, 'image/jpeg', calidadDeseada)
      }

      const reader = new FileReader()
      reader.onload = e => {
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    ocultarDialogo() {
      this.previewUrl = null
      this.selectedFile = null
      this.verDialogoImagen = false
      this.verVisualizacion = false
    },
  },
}
</script>
<style scoped>
.ImagenShowReemplazo {
  max-height: 80%;
  max-width: 80%;
  width: 80%;
  height: 80%;
  border-radius: 8px;
}
.bottom-container {
  margin-top: 50px;
}
.imagen-preview {
  border-radius: 5px;
  object-fit: fill;
  width: 80%;
  height: 80%;
  max-width: 80%;
  max-height: 80%;
}
</style>
