<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Inspección"
          label-for="h-inspeccion"
          label-cols-md="4"
        >
          <b-form-input
            id="inspeccion"
            :value="inspeccion.id"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Fecha Creación"
          label-for="fechaCreacion"
          label-cols-md="4"
        >
          <b-form-input
            id="fechaCreacion"
            :value="inspeccion.fechaCreacion"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Responsable"
          label-for="h-responsable"
          label-cols-md="4"
        >
          <b-form-input
            id="responsable"
            :value="inspeccion.usuario.nombre"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Ruta"
          label-for="h-ruta"
          label-cols-md="4"
        >
          <b-form-input
            id="ruta"
            :value="inspeccion.ruta.nombre"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Tipo"
          label-for="h-tipo"
          label-cols-md="4"
        >
          <b-form-input
            id="tipo"
            :value="inspeccion.tipo"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Estado"
          label-for="h-estado"
          label-cols-md="4"
        >
          <b-form-input
            id="estado"
            :value="inspeccion.estado"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Última Actualización"
          label-for="h-actualizacion"
          label-cols-md="4"
        >
          <b-form-input
            id="actualizacion"
            :value="getLastUpdate()"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Cantidad de Localidades"
          label-for="h-localidades"
          label-cols-md="4"
        >
          <b-form-input
            id="estado"
            :value="inspeccion.ruta.localidades.length"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Tracking">
        <b-col cols="12">
          <br>
          <!--TABLA-->
          <b-table
            responsive="sm"
            :items="inspeccion.imagenes"
            :fields="adjuntosColumns"
            show-empty
            empty-text="No existen registros!"
          >
            <template #cell(documento)>
              <span class="align-text-top text-capitalize">
                Fotografía
              </span>
            </template>
            <!--BOTON-->
            <template #cell(visualizar)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                @click="mostrarImagen(data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </template>
            <template #cell(comentario)="data">
              <span>{{ data.item.comentarios }}</span>
            </template>
          </b-table>
        </b-col>
      </b-tab>
      <!--Tab Fallas-->
      <b-tab title="Fallas">
        <b-table
          responsive="sm"
          :items="tickets"
          :fields="columnFallas"
        >
          <template #cell(poste)="data">
            <span class="align-text-top text-capitalize">
              {{ data.item.idPuntoIluminacion }}
            </span>
          </template>
          <template #cell(tipoFalla)="data">
            <span class="align-text-top text-capitalize">
              {{ data.item.falla.nombre }}
            </span>
          </template>
          <template #cell(visualizar)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              @click="mostrarImagen(data.item.imagenes[0])"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="success"
      ok-title="Aceptar"
      modal-class="modal-success"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <b-row>
        <b-col cols="12">
          <center>
            <b-img
              :src="urlImagen"
              fluid
              alt="Documento Cargado')"
            />
          </center>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BTable, BButton, VBPopover, VBModal, BImg, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { calcularFecha } from '@/utils/fechas'
import { findTickets } from '@/utils/tickets'

export default {
  name: 'InspeccionRutaDetalle',
  directives: {
    Ripple,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BImg,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columnFallas: [
        {
          key: 'punto.numeroPoste',
          label: 'Poste',
          sortable: true,
        },
        {
          key: 'tipoFalla',
          label: 'Tipo Falla',
          sortable: true,
        },
        {
          key: 'visualizar',
          label: 'Visualizar',
        },
      ],
      adjuntosColumns: [
        {
          key: 'tipo',
          label: 'Estado',
          sortable: true,
        },
        {
          key: 'documento',
          label: 'Documento',
          sortable: true,
        },
        {
          key: 'visualizar',
          sortable: true,
        },
        {
          key: 'comentario',
          label: 'comentario',
          sortable: true,
        },
      ],
      tickets: [],
      urlImagen: '',
      verDialogoDocumento: false,
    }
  },
  async created() {
    this.inspeccion.fechaCreacion = calcularFecha(this.inspeccion.fechaCreacion)
    this.tickets = await findTickets({
      where: {
        and: [
          { canal: 'INSPECCION' },
          { idInspeccion: this.inspeccion.id },
        ],
      },
    }, true)
  },
  methods: {
    getLastUpdate() {
      if (this.inspeccion.imagenes.length > 0) {
        return calcularFecha(this.inspeccion.imagenes[this.inspeccion.imagenes.length - 1].fechaHora)
      }
      return ''
    },
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
  },
}
</script>

<style scoped>

</style>
