<template>
  <div>
    <b-row>
      <b-col cols="12">
        <label>Ingrese un comentario:</label>
        <b-form-textarea
          v-model="razon"
          row="2"
        />
      </b-col>
      <b-col cols="12">
        <br>
      </b-col>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="relief-danger"
          block
          @click="anularTicket"
        >
          Anular Ticket
        </b-button>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BButton, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { updateCreateTicket } from '@/utils/tickets'

export default {
  name: 'ModalDetailPoste',
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      razon: '',
    }
  },
  methods: {
    anularTicket() {
      console.log(this.ticket)
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que desea anular el ticket, está opción no es reversible!', {
          title: 'Por favor Confirmar',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (this.razon.trim() === '') {
              this.error('Debe ingresar un comentario!')
              return
            }
            const usuario = JSON.parse(localStorage.getItem('userData'))
            this.ticket.estado = 'ANULADO'
            this.ticket.seguimiento.push({
              tipo: 'Anulación del Ticket',
              estado: 'Anulación del Ticket',
              fechaHora: new Date(),
              comentarios: this.razon,
              usuario: {
                id: usuario.id,
                nombre: `${usuario.firstName} ${usuario.firstSurname}`,
                name: `${usuario.firstName} ${usuario.firstSurname}`,
                email: usuario.email,
                telefono: '',
              },
            })
            const resultado = await updateCreateTicket(this.ticket, 1)
            if (resultado === null) {
              this.mensajeError('Ocurrió un problema actualizando el ticket')
              return
            }
            this.success('Anulación Ticket', 'Ticket actualizado correctamente!')
            this.$emit('cerrar-dialogo')
          }
        })
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}

.card-poste .text {
  color: #211f1f70;
}
</style>
