<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      :variant="getVariant()"
      block
      @click="verDetallePoste"
    >
      {{ getTitulo() }}
    </b-button>
    <b-modal
      :id="ticket.id"
      :key="ticket.id"
      v-model="verDialogoDocumento"
      :no-close-on-backdrop="true"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Inspecciones"
    >
      <seguimiento-ticket
        :id="ticket.id"
        :key="ticket.id"
        :ticket="ticket"
        :operacion="operacion"
        :item="item"
        @cerrar-dialog="ocultarDialogo"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SeguimientoTicket from '@/components/Tickets/tecnico/SeguimientoTicket.vue'
import { tiempoTranscurridoTicket } from '@/utils/funciones'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BButton,
    SeguimientoTicket,
  },
  directives: {
    Ripple,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    getVariant() {
      if (this.operacion === 'V' && this.item === 'VERIFICAR') {
        const tiempoTranscurrido = this.calcularTiempoTranscurrido()
        return tiempoTranscurrido < 60 ? 'relief-success' : 'relief-danger'
      }
      if (this.item === '') {
        if (this.operacion === 'N') return 'relief-success'
        return this.operacion === 'I' ? 'relief-success' : 'relief-danger'
      }
      return 'relief-info'
    },
    calcularTiempoTranscurrido() {
      return tiempoTranscurridoTicket(this.ticket.fechaCreacion)
    },
    verDetallePoste() {
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
    },
    getTitulo() {
      if (this.item === '') {
        if (this.operacion === 'N') return 'Iniciar Proceso'
        return this.operacion === 'I' ? 'Ingresar Seguimiento Ticket' : 'Finalizar Ticket'
      }
      if (this.operacion === 'F' && this.item === 'ATENDER') return 'Resolver Ticket'
      if (this.operacion === 'V' && this.item === 'VERIFICAR') return 'Verificar Ticket'
      return ''
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}
.modal{
  min-height: 50px;
  overflow-y: 50px;
}

.card-poste .text {
  color: #211f1f70;
}
</style>
