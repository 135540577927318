<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-warning"
      block
      @click="showFalla()"
    >
      {{ 'Ingresar Falla' }}
    </b-button>
    <!-- Crear ticket -->
    <b-modal
      v-model="verTicket"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      title="Creacion Ticket"
    >
      <IngresoFallaInspeccion @cerrar-dialog="ocultarDialogo" />
    </b-modal>
  </div>

</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import IngresoFallaInspeccion from '@/views/tickets/IngresoFallaInspeccion.vue'

export default {
  components: {
    BModal,
    BButton,
    IngresoFallaInspeccion,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      verTicket: false,
      opcion: '',
    }
  },
  methods: {
    showFalla() {
      this.ocultarDialogo()
      this.verTicket = true
    },
    ocultarDialogo() {
      this.verTicket = false
      this.$emit('refrescar-pagina')
    },
  },
}
</script>

<style scoped>
</style>
